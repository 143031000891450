import React, { useState, useEffect } from 'react';
import Facemash from './Facemash';



function App() {


  return (
    <Facemash/>
  );
}

export default App;
